<template>
  <v-progress-circular
    v-if="loading"
    class="loading"
    color="info"
    indeterminate
  />
</template>

<script>
  import loading from '@/shared/models/loading'

  export default {
    name: 'GokbProgressOverlay',
    computed: {
      loading () {
        return loading.isLoading()
      }
    }
  }
</script>

<style scoped>
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* transparent overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
</style>
