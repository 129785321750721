import { render, staticRenderFns } from "./gokb-table.vue?vue&type=template&id=270a4c7e&scoped=true"
import script from "./gokb-table.vue?vue&type=script&lang=js"
export * from "./gokb-table.vue?vue&type=script&lang=js"
import style0 from "./gokb-table.vue?vue&type=style&index=0&id=270a4c7e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270a4c7e",
  null
  
)

export default component.exports