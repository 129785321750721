let error

export default {
  get () {
    return error
  },
  set (value) {
    error = value
  }
}
